import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"

// Styling
import textStyles, { fontWeights } from "../styles/textStyles"
import colors, { shadows } from "../styles/colors"
import mq from "../styles/breakpoints"

// Components
import Seo from "../components/Seo"
import ContentWrapper from "../components/ContentWrapper"
import Button from "../components/atoms/Button"
import RequestDemo from "../components/RequestDemo"
import CustomPrismicTitle from "../components/atoms/CustomPrismicTitle"
import CustomRichText from "../components/atoms/CustomRichText"

// Constants

// Hooks
import { useLocale } from "../hooks/useLocale"

// Types
import { IResourcesPage, IResource } from "../prismic-types/resources"
import { IDemoPage } from "../prismic-types/request-demo-page"

// Utils
import { verifyDownloadUrl } from "../utils/hashUrl"

interface IProps {
  content: IResourcesPage
  demoPage: IDemoPage
}

interface IResourceList {
  resources: IResource[]
  allowDownload: boolean
  showDownloadButton?: boolean
  downloadClick?: (title?: string) => void
}

export const ResourcesList: React.FC<IResourceList> = ({
  resources,
  downloadClick,
  allowDownload,
  showDownloadButton = true,
}) => {
  const { getLocalizedLink, isDutch } = useLocale()

  return (
    <ResourcesContainer>
      {resources.map((resource, index) => (
        <Card key={`resource-card-${index}`}>
          <ImageWrapper src={resource.image?.url?.src} />
          <TextWrapper>
            <TextSection>
              <CardTitle>{resource.title}</CardTitle>
              <CardDescription>{resource.text}</CardDescription>
            </TextSection>
            {allowDownload ? (
              <DownloadButtonContainer>
                <DownloadButton
                  variant="download"
                  label="Download"
                  isExternal
                  href={resource.link || ""}
                />
              </DownloadButtonContainer>
            ) : (
              <ButtonContainer>
                <StyledButton
                  href={getLocalizedLink(`/resources/${resource.slug}`)}
                  label={isDutch ? "Lees verder" : "Read more"}
                  variant="secondary"
                />
                {!!showDownloadButton && (
                  <Button
                    variant="download"
                    onClick={() =>
                      downloadClick && downloadClick(resource.title)
                    }
                  />
                )}
              </ButtonContainer>
            )}
          </TextWrapper>
        </Card>
      ))}
    </ResourcesContainer>
  )
}

const Resources: React.FC<IProps> = ({ content, demoPage }) => {
  const [requestedResource, setRequestedResource] = useState<string>()
  const [allowDownload, setAllowDownload] = useState<boolean>(false)
  const slug = "resources"

  useEffect(() => {
    const isVerified = verifyDownloadUrl(window.location.href)
    setAllowDownload(isVerified)
  }, [])

  return (
    <>
      <Seo
        title={content.meta.title}
        description={content.meta.description}
        dateModified={new Date().toISOString()}
        datePublished={new Date().toISOString()}
        lang={content.meta.lang}
        slug={slug}
        breadCrumbItems={[{ name: "Resources", url: slug }]}
      />
      <Section>
        <IntroContainer>
          {content.page.title && (
            <CustomPrismicTitle data={content.page.title} />
          )}
          {content.page.intro && (
            <CustomRichText
              data={content.page.intro}
              color={colors.main["300"]}
            />
          )}
        </IntroContainer>

        {content.page.resourceCategories &&
          content.page.resourceCategories.map((category) => {
            const filteredResources =
              content?.page?.resources &&
              content?.page?.resources.filter(
                (resource) => resource.category === category.uid
              )

            return (
              <StyledContentWrapper key={`resource-category-${category.label}`}>
                {category.label && category.uid && (
                  <CategoryTitle>{category.label}</CategoryTitle>
                )}

                <ResourcesList
                  resources={
                    filteredResources?.map((resource) => ({
                      slug: resource.uid,
                      title: resource.heading,
                      text: resource.description,
                      image: resource.image,
                      link: resource.downloadLink,
                    })) || []
                  }
                  allowDownload={allowDownload}
                  downloadClick={(title) => setRequestedResource(title)}
                />
              </StyledContentWrapper>
            )
          })}
      </Section>
      {requestedResource && (
        <RequestDemo
          title={demoPage.downloadTitle}
          text={demoPage.downloadText}
          variant="download"
          labels={demoPage.labels}
          download={requestedResource}
          closeModal={() => setRequestedResource(undefined)}
          onSuccess={() => {
            setRequestedResource(undefined)
          }}
        />
      )}
    </>
  )
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 64px;
  background: ${colors.grayPurpleLight};
  padding-bottom: 120px;
`

const IntroContainer = styled(ContentWrapper)`
  padding: 96px 16px 0px;

  ${mq.from.S`
    padding: 104px 48px 0px;
  `}

  ${mq.from.L`
    padding: 141px 88px 0px;
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  padding: 0px 16px;

  ${mq.from.S`
    padding: 0px 48px;
  `}

  ${mq.from.L`
    padding: 0px 88px;
  `}
`

const CategoryTitle = styled.h3`
  color: ${colors.main["100"]};
  ${textStyles.titleM};
  margin: 0 0 32px;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
  gap: 16px;

  > *:second-child {
    flex: 0 0 auto;
  }
`

const DownloadButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`

const StyledButton = styled(Button)`
  font-weight: ${fontWeights.medium};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const DownloadButton = styled(Button)`
  font-weight: ${fontWeights.medium};
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ResourcesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
`

const Card = styled.div`
  width: 100%;
  background: ${colors.white};
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  ${shadows.depthOne};

  ${mq.from.M`
    width: calc(50% - 16px);
    flex-direction: row;
    justify-content: space-between;
  `}
`

const CardTitle = styled.h5`
  color: ${colors.main["200"]};
  ${textStyles.titleS};
  font-size: 18px;
  line-height: 26px;
  font-weight: ${fontWeights.medium};
  ${mq.from.M`
    font-size: 22px;
    font-weight: ${fontWeights.medium};
  `}
  margin: 0 0 16px;
`

const CardDescription = styled.p`
  color: ${colors.main["400"]};
  ${textStyles.body};

  // Truncation styles
  display: -webkit-box; // Display as a webkit box to support truncation
  -webkit-box-orient: vertical; // Set the orientation to vertical
  overflow: hidden; // Hide overflowing content
  text-overflow: ellipsis; // Add ellipsis for overflow
  -webkit-line-clamp: 3; // Show only 2 lines and truncate the rest
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 0px;
  ${mq.from.M`
    width: 50%;
  `}
`

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageWrapper = styled.div<{ src?: string }>`
  background: ${colors.grey};
  background-image: url("${({ src }) => src}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  min-height: 128px;
  width: 100%;
  margin-bottom: 16px;

  ${mq.from.S`
    min-height: 152px;
  `}

  ${mq.from.M`
    width: 50%;
    height: 100%;
    margin-bottom: 0;
  `}
`

export const query = graphql`
  query resourcesPageV2($locale: String!) {
    prismicResourcesPageV2(lang: { eq: $locale }) {
      lang
      first_publication_date
      last_publication_date
      data {
        # Meta
        meta_title
        meta_description

        # Page
        description {
          html
        }
        heading {
          html
          text
          raw
        }
        resource_categories {
          category {
            document {
              ... on PrismicResourceCategory {
                uid
                data {
                  label
                }
              }
            }
          }
        }
      }
    }
    allPrismicResourceCategory(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang
          data {
            label
          }
        }
      }
    }
    allPrismicResourceTemplate(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          uid
          data {
            category {
              document {
                ... on PrismicResourceCategory {
                  id
                  uid
                  data {
                    label
                  }
                }
              }
            }
            heading {
              text
            }
            description {
              text
            }
            image {
              alt
              url
              fluid {
                aspectRatio
                base64
                sizes
                src
                srcSet
                srcSetWebp
                srcWebp
              }
            }
            download_link
          }
        }
      }
    }
    prismicRequestADemoPage(lang: { eq: $locale }) {
      last_publication_date
      lang
      first_publication_date
      data {
        # Meta
        meta_title
        meta_description

        # Page
        title {
          text
        }
        text {
          html
        }
        download_title {
          text
        }
        download_text {
          html
        }
        name
        notes
        phone
        email
        company
        interest
        employees
      }
    }
  }
`

export default ({ data }: any) => {
  if (!data) return null
  const {
    data: raw,
    lang,
    first_publication_date,
    last_publication_date,
  } = data.prismicResourcesPageV2
  const { data: demoPageData } = data.prismicRequestADemoPage

  const resourceCategories = raw.resource_categories
    .map(({ category }: any) => {
      const categoryDoc = category.document
      if (!categoryDoc) return null
      return {
        uid: category.document.uid,
        label: category.document.data.label,
      }
    })
    .filter((category: any) => !!category)

  const resources = data.allPrismicResourceTemplate.edges.map(
    ({ node }: any) => {
      const relatedCategoryDocument = node.data.category?.document
      return {
        uid: node.uid,
        category: relatedCategoryDocument?.uid,
        heading: node.data.heading.text,
        description: node.data.description.text,
        image: {
          alt: node.data?.image?.alt,
          url: node.data?.image?.fluid,
          imageUrl: node.data?.image?.url,
        },
        downloadLink: node.data.download_link,
      }
    }
  )

  if (!raw) return null

  const meta = {
    title: raw.meta_title,
    description: raw.meta_description,
    publishedDate: first_publication_date,
    modifiedDate: last_publication_date,
    lang,
  }

  const page = {
    title: raw.heading.raw,
    intro: raw.description?.html,
    resourceCategories,
    resources,
  }

  const demoPage: IDemoPage = {
    text: demoPageData.text.html,
    title: demoPageData.title.text,
    downloadText: demoPageData.download_text.html,
    downloadTitle: demoPageData.download_title.text,
    labels: {
      name: demoPageData.name,
      company: demoPageData.company,
      email: demoPageData.email,
      notes: demoPageData.notes,
      phone: demoPageData.phone,
      interest: demoPageData.interest,
      employees: demoPageData.employees,
    },
  }

  const content = {
    meta,
    page,
  }

  return <Resources content={content} demoPage={demoPage} />
}
