// Constants
import { MONTHS } from "../constants/date"

// Types
type OrdinalSuffixOf = (i: number) => string
type FormatDate = (d: string) => string

/**
 * Taken from https://stackoverflow.com/a/13627586
 * @param i number
 * @returns number with suffix eg. 22nd
 */
export const ordinalSuffixOf: OrdinalSuffixOf = (i) => {
  const j = i % 10
  const k = i % 100
  if (j === 1 && k !== 11) {
    return i + "st"
  }
  if (j === 2 && k !== 12) {
    return i + "nd"
  }
  if (j === 3 && k !== 13) {
    return i + "rd"
  }
  return i + "th"
}

/**
 * https://stackoverflow.com/a/1353711
 */
export const isValidDate = (d: any) => d instanceof Date && !isNaN(d.getTime())

/**
 * Formats date string and returns formatted version
 * @param d date eg. "2021-06-07"
 * @returns  string eg. "October 21st, 2021"
 */
const formatDate: FormatDate = (d) => {
  if (!d) return ""

  const dateString = new Date(d)

  if (!isValidDate(dateString)) return ""

  const year = dateString.getFullYear()
  const date = ordinalSuffixOf(dateString.getDate())
  const month = MONTHS[dateString.getMonth()]

  return `${month} ${date}, ${year}`
}

/**
 * Converts a date string in the format "YYYY-MM-DD" to "YYYY-MM-DDTHH:MM:SS+TZOFFSET".
 *
 * @param {string} input - The date string in the format "YYYY-MM-DD".
 * @returns {string} - The converted date string in the format "YYYY-MM-DDTHH:MM:SS+TZOFFSET".
 *
 * @example
 * const inputDate = "2023-08-15";
 * const formattedDate = formatDate(inputDate);
 * console.log(formattedDate); // Outputs: "2023-08-15T08:00:00+01:00"
 */
export function formatDateSEO(input: string): string {
  const date = new Date(input)

  // Extract year, month, and day from the date
  const yyyy = date.getUTCFullYear()
  const MM = String(date.getUTCMonth() + 1).padStart(2, "0") // Months are 0-indexed
  const dd = String(date.getUTCDate()).padStart(2, "0")

  // Add desired time and timezone offset for the Netherlands (CET: UTC+1)
  const time = "T08:00:00+01:00"

  return `${yyyy}-${MM}-${dd}${time}`
}

export default formatDate
