/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

// Utils
import { formatDateSEO } from "../utils/dateHelper"

interface IBreadCrumbItem {
  name: string
  url: string
}

type Author = {
  name: string
  url?: string
}
interface Article {
  title: string
  images: string[]
  author: Author[]
}
interface IMeta {
  name: string
  content: string
}
interface FaqItem {
  question: string
  answer: string
}

interface IProps {
  description?: string
  meta?: IMeta[]
  title: string
  imageUrl?: string
  breadCrumbItems?: IBreadCrumbItem[]
  article?: Article
  indexable?: boolean
  slug?: string
  lang?: string
  datePublished: string
  dateModified: string
  faqItems?: FaqItem[]
}

const Seo: React.FC<IProps> = ({
  description = "",
  lang = "en",
  meta = [],
  title: _title,
  imageUrl,
  breadCrumbItems,
  article,
  indexable = true,
  slug = "",
  datePublished,
  dateModified,
  faqItems,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const title = _title || site.siteMetadata.title
  const siteUrl = site.siteMetadata.siteUrl
  const image =
    "https://summ-jm.s3.eu-central-1.amazonaws.com/summ/OpenGraph+image.png" ||
    imageUrl // Schelto wants to use same image for every page.
  const url = `${siteUrl}/${slug}`
  const type: "article" | "website" = article ? "article" : "website"
  const social = {
    instagram: "https://www.instagram.com/getsumm.co/",
    linkedIn: "https://www.linkedin.com/company/getsumm/",
  }
  const isPage = !article
  const index = indexable
    ? "index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
    : "noindex"
  const websiteName = "SUMM - Strong culture, better business"
  const phone = "+31 (0) 6 812 875 69"
  const logo =
    "https://summ-jm.s3.eu-central-1.amazonaws.com/summ/SUMM+Logo+Fullcolor.png"
  const getLocalizedUrl = (_lang: string) =>
    _lang === "en" ? `${siteUrl}/en/${slug}` : url

  const graph = []
  if (isPage) {
    graph.push({
      "@type": "WebPage",
      "@id": `${url}`,
      url,
      inLanguage: lang,
      name: title,
      description,
      isPartOf: {
        "@id": `${siteUrl}/#website`,
      },
      datePublished,
      dateModified,
    })
  }
  if (article) {
    graph.push({
      "@type": "BlogPosting",
      headline: article.title,
      image: article.images,
      datePublished: formatDateSEO(datePublished),
      dateModified: formatDateSEO(dateModified),
      author: article.author.map((author) => ({
        "@type": "Person",
        ...author,
      })),
    })
  }
  if (faqItems && faqItems?.length > 0) {
    graph.push({
      "@type": "FAQPage",
      mainEntity: faqItems.map((item) => ({
        "@type": "Question",
        name: item.question,
        acceptedAnswer: {
          "@type": "Answer",
          text: item.answer,
        },
      })),
    })
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={meta}
    >
      <html lang={lang} />
      <meta name="description" content={metaDescription} />
      <meta name="image" content={image} />
      <meta name="robots" content={index} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content={type} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={image} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <link rel="alternate" hrefLang="x-default" href={url} />
      <link rel="alternate" hrefLang="en" href={getLocalizedUrl("en")} />
      <link rel="alternate" hrefLang="nl" href={getLocalizedUrl("nl")} />

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@graph": [
            {
              "@type": ["Organization", "Brand"],
              "@id": `${siteUrl}/#organization`,
              name: websiteName,
              url: siteUrl,
              legalName: websiteName,
              contactPoint: {
                "@type": "ContactPoint",
                telephone: phone,
                contactType: "Customer Service",
                areaServed: "Netherlands",
                availableLanguage: ["English", "Dutch"],
                email: "hello@getsumm.co",
              },
              address: {
                "@type": "PostalAddress",
                "@id": `${siteUrl}/#postalAddress`,
                postalCode: "1021 KL",
                addressLocality: "Amsterdam",
                addressRegion: "Noord-Holland",
                streetAddress: "Gedempt Hamerkanaal 33",
                addressCountry: "NL",
              },
              logo: {
                "@type": "ImageObject",
                inLanguage: "de-DE",
                "@id": `${siteUrl}/#/schema/logo/image/`,
                url: logo,
                width: 160,
                height: 160,
                caption: websiteName,
              },
              image: {
                "@id": logo,
              },
              sameAs: [social.instagram, social.linkedIn],
            },
            {
              "@type": "WebSite",
              "@id": `${siteUrl}#website`,
              url: siteUrl,
              name: websiteName,
              publisher: {
                "@id": `${siteUrl}/#organization`,
              },
              inLanguage: lang,
            },
            {
              "@type": "BreadcrumbList",
              name: websiteName,
              itemListElement: [
                {
                  "@type": "ListItem",
                  position: 1,
                  name: "Home",
                  item: `${siteUrl}`,
                },
                ...(breadCrumbItems
                  ? breadCrumbItems.map((item, idx) => ({
                      "@type": "ListItem",
                      position: idx + 2,
                      name: item.name,
                      item: `${siteUrl}/${item.url}`,
                    }))
                  : []),
              ],
            },
            ...graph,
          ],
        })}
      </script>
    </Helmet>
  )
}

export default Seo
