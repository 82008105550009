import React, { FC } from "react"
import styled from "styled-components"

// styles
import mq from "../../styles/breakpoints"
import colors from "../../styles/colors"
import { fontWeights } from "../../styles/textStyles"

interface IProps {
  data: string
  color?: string
}
const CustomRichText: FC<IProps> = ({ data, color }) => {
  return (
    <StyledRichText
      dangerouslySetInnerHTML={{
        __html: data || "",
      }}
      color={color}
    />
  )
}

export default CustomRichText

const StyledRichText = styled.div<{ color?: string }>`
  opacity: 0.8;
  & p {
    font-size: 16px;
    line-height: 26px;
    font-weight: ${fontWeights.regular};
    color: ${({ color }) => color || colors.main["200"]};
    letter-spacing: -1%;
    margin-bottom: 0px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    & *:last-child {
      margin-bottom: 0;
    }

    ${mq.from.M`
      font-size: 18px;
      line-height: 30px;
    `}

    & a {
      color: ${colors.purpleLight};
    }
  }

  & li {
    font-size: 16px;
    line-height: 26px;
    font-weight: ${fontWeights.regular};
    color: ${({ color }) => color || colors.main["200"]};
    letter-spacing: -1%;
    margin-bottom: 0;

    ${mq.from.M`
      font-size: 18px;
      line-height: 30px;
    `}
  }
`
