import React, { FC, Fragment } from "react"
import styled from "styled-components"

// styles
import mq from "../../styles/breakpoints"
import colors from "../../styles/colors"
import { fontWeights } from "../../styles/textStyles"
import { fonts } from "../../styles/fonts"

export interface IPrismicTitle {
  type:
    | "heading1"
    | "heading2"
    | "heading3"
    | "heading4"
    | "heading5"
    | "heading6"
  text: string
  align: "left" | "center" | "right"
  color?: string
}
interface IProps {
  data: IPrismicTitle[]
  color?: string
}
const CustomPrismicTitle: FC<IProps> = ({ data, color }) => {
  return (
    <Fragment>
      {data.map((item, _idx) => (
        <RenderPrismicTitle
          key={`title-${_idx}`}
          text={item.text}
          type={item.type}
          align="center"
          color={color}
        />
      ))}
    </Fragment>
  )
}

const RenderPrismicTitle: FC<IPrismicTitle> = ({ text, type, color }) => {
  if (type === "heading1") return <HeadingOne color={color}>{text}</HeadingOne>
  if (type === "heading2") return <HeadingTwo color={color}>{text}</HeadingTwo>
  if (type === "heading3")
    return <HeadingThree color={color}>{text}</HeadingThree>
  if (type === "heading4")
    return <HeadingFour color={color}>{text}</HeadingFour>
  if (type === "heading5")
    return <HeadingFive color={color}>{text}</HeadingFive>
  if (type === "heading6") return <HeadingSix color={color}>{text}</HeadingSix>
  return <p>{text}</p>
}

export default CustomPrismicTitle

export const HeadingOne = styled.h1<{ color?: string }>`
  font-size: 32px;
  line-height: 40px;
  font-weight: ${fontWeights.bold};
  color: ${({ color }) => color || colors.main["100"]};
  letter-spacing: -1.5px;
  margin-bottom: 0;

  ${mq.from.M`
    font-size: 48px;
    line-height: 60px;
  `}
`
export const HeadingTwo = styled.h2<{ color?: string }>`
  font-size: 28px;
  line-height: 36px;
  font-weight: ${fontWeights.bold};
  letter-spacing: -1px;
  color: ${({ color }) => color || colors.main["100"]};
  margin-bottom: 0px;

  ${mq.from.M`
  font-size: 40px;
  line-height: 52px;
  `}
`

export const HeadingThree = styled.h3<{ color?: string }>`
  font-family: ${fonts.primary};
  font-size: 28px;
  line-height: 36px;
  font-weight: ${fontWeights.bold};
  letter-spacing: -1px;
  color: ${({ color }) => color || colors.main["100"]};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 32px;
    line-height: 40px;
  `}
`

export const HeadingFour = styled.h4<{ color?: string }>`
  font-family: ${fonts.primary};
  font-size: 20px;
  line-height: 28px;
  font-weight: ${fontWeights.bold};
  letter-spacing: -1px;
  color: ${({ color }) => color || colors.main["100"]};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 24px;
    line-height: 32px;
  `}
`

export const HeadingFive = styled.h5<{ color?: string }>`
  font-family: ${fonts.primary};
  font-size: 18px;
  line-height: 26px;
  font-weight: ${fontWeights.bold};
  color: ${({ color }) => color || colors.main["100"]};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 22px;
    line-height: 30px;
  `}
`

export const HeadingSix = styled.h6<{ color?: string }>`
  font-family: ${fonts.primary};
  font-size: 10px;
  line-height: 16px;
  font-weight: ${fontWeights.bold};
  letter-spacing: -1px;
  color: ${({ color }) => color || colors.main["100"]};
  margin-bottom: 0px;

  ${mq.from.M`
    font-size: 14px;
    line-height: 22px;
  `}
`
